<template>
  <div class="container">
    
    <van-field v-model="form.name" readonly input-align="right" label="房间名称" />

    <!-- <van-field v-model="form.name" readonly>
      <span slot="label" class="field-label">房间状态</span>
    </van-field> -->


    <van-field v-model="roomStatus" readonly input-align="right" label="房间状态" ></van-field>
    <van-field v-model="form.roomAmountArea" readonly input-align="right" label="总面积" />
    <van-field v-model="form.roomSharedArea" readonly input-align="right" label="公摊面积" />
    <van-field v-model="form.roomDetailAddress" readonly input-align="right" label="详细地址" />
    <van-field v-model="form.towards" readonly input-align="right" label="房屋朝向" />
    <van-field v-model="form.floorNo" readonly input-align="right" label="楼层" />
    <van-field v-model="roomType" readonly input-align="right" label="房屋类型" />
    <van-field v-model="form.certificateNo" input-align="right" readonly label="产权证编号" />
    <van-field v-model="form.ownerName" readonly input-align="right" label="产权证姓名" />
    <!-- <van-field v-model="form.ownerIdCard" readonly input-align="right" label="产权证身份证号" /> -->
    <van-field v-model="form.ownerPhone" readonly input-align="right" label="产权证电话" />



  </div>
</template>
<script>
import { getDictionary } from '@/api/registerApi'
import { getPropertyDetail } from '@/api/houseApi'
export default {
  // props: {
  //   id: {
  //     type: String,
  //     default: '',
  //   }
  // },
  data() {
    return {
      form: {},
      roomStatus: '',
      roomType: '',
      statusList: [],
      typeList: [],
    }
  },
  async created() {
    await this.getRoomStatus()
    await this.getRoomType()
    
    await this.getInfo(this.$route.query.id)
  },
  // watch: {
  //   id(val) {
  //     if(val) {
  //       this.getInfo(this.id)
  //     }
  //   }
  // },
  mounted() {},
  methods: {
    async getRoomStatus() {
      await getDictionary('room_status').then(res => {
        this.statusList = res.data
      })
    },
    async getRoomType() {
      await getDictionary('property_right_type').then(res => {
        this.typeList = res.data
      })
    },
    async getInfo(id) {
      this.$store.commit('SET_IS_LOADING', true)
      await getPropertyDetail(id).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        this.form = res.data
        this.statusList.map(v => {
          if(v.dictKey == res.data.status) {
            return this.roomStatus = v.dictValue
          }
        })

        this.typeList.map(v => {
          if(v.dictKey == res.data.certificateType) {
            return this.roomType = v.dictValue
          }
        })
      })
    },

  },
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/form.css';

</style>
